/* stylelint-disable no-descending-specificity */
.carousel {
    $this: &;
    $inidicators-height: 22px;

    margin-bottom: #{$inidicators-height};

    @media (pointer: coarse) and (max-width: 991.98px) {
        padding-inline: 0;
    }

    &:has(.carousel-indicators) {
        margin-bottom: 36px;
    }

    // Add space left if the previous button will be visible (only on non-touchscreens)
    &:not(.custom-carousel):has(.carousel-control-prev) {
        @media (pointer: fine), (min-width: 992px) {
            padding-left: 60px;
        }
    }

    // Add space right if the next button will be visible (only on non-touchscreens)
    &:not(.custom-carousel):has(.carousel-control-next) {
        @media (pointer: fine), (min-width: 992px) {
            padding-right: 60px;
        }
    }

    &-item {
        padding: 0 15px;

        > img, .tile-image {
            width: 100%;
            max-width: 400px;
            max-height: 400px;
            aspect-ratio: 1 / 1;
            object-fit: contain;
            position: relative;
            background: var(--skin-image-background-color-1);
            padding: 40px;
            clip-path: circle(50% at 50% 50%);
            margin: auto;
        }
    }

    // Done this way to increase specificity
    #{$this}-control-next,
    #{$this}-control-prev {
        width: 2.5rem;
        height: 2.5rem;
        margin: auto 10px;
        opacity: 1;

        @media (pointer: coarse) and (max-width: 991.98px) {
            display: none;
        }

        &:hover {
            .icon-next,
            .icon-prev {
                background: var(--skin-primary-color-1);

                &::before {
                    color: var(--skin-primary-color-invert-1);
                }
            }
        }

        &:focus {
            outline: none;
        }

        .icon-next,
        .icon-prev {
            width: 2.5rem;
            height: 2.5rem;
            padding: 0;
            border: 1px solid var(--skin-primary-color-1);
            border-radius: 50%;

            &::before {
                color: var(--skin-primary-color-1);
                vertical-align: middle;
                margin-left: 2px;
            }
        }

        .icon-prev::before {
            margin-inline: 0 2px;
        }
    }

    &-indicators {
        margin: 0;
        bottom: -#{$inidicators-height};
        z-index: 1;

        li {
            margin: 0 5px;
            width: 10px;
            height: 10px;
            border: 0;
            outline: 0;
            background: var(--skin-primary-color-2);
            border-radius: 50%;
            opacity: 1;
            transition: none;

            &:focus,
            &:hover,
            &.active {
                background: var(--skin-primary-color-1);
            }
        }
    }
}

.custom-carousel {
    position: static;
    padding: 0;
    margin: 0 30px;

    .carousel-inner {
        display: flex;
        padding-bottom: 10px;
    }

    .carousel-item > * {
        height: 100%;
    }

    .carousel-control-prev,
    .carousel-control-next {
        display: flex;
        padding-bottom: 10px;
        margin-inline: 10px;
    }

    &--3 {
        .carousel-inner {
            @media (min-width: 769px) {
                justify-content: center;
            }
        }

        .carousel-item {

            @media (min-width: 769px) and (max-width: 1199.98px) {
                /* Show 3rd slide for smooth animations */
                &.active+.carousel-item+.carousel-item {
                    position: absolute;
                    top: 0;
                    right: -50%;
                    z-index: -1;
                    display: block;
                    visibility: visible;
                }
            }

            @media (min-width: 769px) {
                margin-right: 0;

                &.active {
                    /* show 2 items */
                    +.carousel-item {
                        display: block;
                    }

                    &:not(.carousel-item-right, .carousel-item-left),
                    &:not(.carousel-item-right, .carousel-item-left)+.carousel-item {
                        transition: none;
                    }
                }

                &.carousel-item-next {
                    position: relative;
                    transform: translate3d(0, 0, 0);
                }

                /* left or forward direction */
                &.active.carousel-item-left+.carousel-item-next.carousel-item-left,
                &.carousel-item-next.carousel-item-left+.carousel-item,
                &.carousel-item-next.carousel-item-left+.carousel-item+.carousel-item {
                    transform: translate3d(-100%, 0, 0);
                    visibility: visible;
                }

                /* farthest right hidden item must be also positioned for animations */
                &.carousel-item-prev.carousel-item-right {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    display: block;
                    visibility: visible;
                }

                /* right or prev direction */
                &.active.carousel-item-right+.carousel-item-prev.carousel-item-right,
                &.carousel-item-prev.carousel-item-right+.carousel-item,
                &.carousel-item-prev.carousel-item-right+.carousel-item+.carousel-item {
                    position: relative;
                    transform: translate3d(100%, 0, 0);
                    display: block;
                    visibility: visible;
                }
            }

            @media (min-width: 1200px) {
                &.active {
                    /* show 3rd of 3 item slide */
                    +.carousel-item+.carousel-item {
                        display: block;
                    }

                    /* Show 4th slide for smooth animations */
                    +.carousel-item+.carousel-item+.carousel-item {
                        position: absolute;
                        top: 0;
                        right: -33.3333%;
                        z-index: -1;
                        display: block;
                        visibility: visible;
                    }

                    &:not(.carousel-item-right, .carousel-item-left)+.carousel-item+.carousel-item {
                        transition: none;
                    }
                }

                /* left or forward direction */
                &.carousel-item-next.carousel-item-left+.carousel-item+.carousel-item+.carousel-item {
                    position: relative;
                    transform: translate3d(-100%, 0, 0);
                    visibility: visible;
                }

                /* right or prev direction */
                &.carousel-item-prev.carousel-item-right+.carousel-item+.carousel-item+.carousel-item {
                    position: relative;
                    transform: translate3d(100%, 0, 0);
                    display: block;
                    visibility: visible;
                }
            }
        }
    }

    &--4 {
        .carousel-inner {
            justify-content: center;
        }

        .carousel-item {
            padding-inline: 10px;
            margin-right: 0;

            &.active {
                /* show 2 items */
                +.carousel-item {
                    display: block;
                }

                &:not(.carousel-item-right, .carousel-item-left),
                &:not(.carousel-item-right, .carousel-item-left)+.carousel-item {
                    transition: none;
                }
            }

            &.carousel-item-next {
                position: relative;
                transform: translate3d(0, 0, 0);
            }

            /* left or forward direction */
            &.active.carousel-item-left+.carousel-item-next.carousel-item-left,
            &.carousel-item-next.carousel-item-left+.carousel-item,
            &.carousel-item-next.carousel-item-left+.carousel-item+.carousel-item {
                transform: translate3d(-100%, 0, 0);
                visibility: visible;
            }

            /* farthest right hidden item must be also positioned for animations */
            &.carousel-item-prev.carousel-item-right {
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                display: block;
                visibility: visible;
            }

            /* right or prev direction */
            &.active.carousel-item-right+.carousel-item-prev.carousel-item-right,
            &.carousel-item-prev.carousel-item-right+.carousel-item,
            &.carousel-item-prev.carousel-item-right+.carousel-item+.carousel-item {
                position: relative;
                transform: translate3d(100%, 0, 0);
                display: block;
                visibility: visible;
            }

            @media (max-width: 768.98px) {
                /* Show 3rd slide for smooth animations */
                &.active+.carousel-item+.carousel-item {
                    position: absolute;
                    top: 0;
                    right: -50%;
                    z-index: -1;
                    display: block;
                    visibility: visible;
                }
            }

            @media (min-width: 769px) and (max-width: 1199.98px) {
                &.active {
                    /* show 3rd of 3 item slide */
                    +.carousel-item+.carousel-item {
                        display: block;
                    }

                    /* Show 4th slide for smooth animations */
                    +.carousel-item+.carousel-item+.carousel-item {
                        position: absolute;
                        top: 0;
                        right: -33.3333%;
                        z-index: -1;
                        display: block;
                        visibility: visible;
                    }

                    &:not(.carousel-item-right, .carousel-item-left)+.carousel-item+.carousel-item {
                        transition: none;
                    }
                }

                /* left or forward direction */
                &.carousel-item-next.carousel-item-left+.carousel-item+.carousel-item+.carousel-item {
                    position: relative;
                    transform: translate3d(-100%, 0, 0);
                    visibility: visible;
                }

                /* right or prev direction */
                &.carousel-item-prev.carousel-item-right+.carousel-item+.carousel-item+.carousel-item {
                    position: relative;
                    transform: translate3d(100%, 0, 0);
                    display: block;
                    visibility: visible;
                }
            }

            @media (min-width: 1200px) {
                padding-inline: 20px;

                &.active {
                    /* show 4th of 4 item slide */
                    +.carousel-item+.carousel-item,
                    +.carousel-item+.carousel-item+.carousel-item {
                        display: block;
                    }

                    /* Show 5th slide for smooth animations */
                    +.carousel-item+.carousel-item+.carousel-item+.carousel-item {
                        position: absolute;
                        top: 0;
                        right: -25%;
                        z-index: -1;
                        display: block;
                        visibility: visible;
                    }

                    &:not(.carousel-item-right, .carousel-item-left)+.carousel-item+.carousel-item,
                    &:not(.carousel-item-right, .carousel-item-left)+.carousel-item+.carousel-item+.carousel-item {
                        transition: none;
                    }
                }

                /* left or forward direction */
                &.carousel-item-next.carousel-item-left+.carousel-item+.carousel-item+.carousel-item+.carousel-item {
                    position: relative;
                    transform: translate3d(-100%, 0, 0);
                    visibility: visible;
                }

                /* right or prev direction */
                &.carousel-item-prev.carousel-item-right+.carousel-item+.carousel-item+.carousel-item+.carousel-item {
                    position: relative;
                    transform: translate3d(100%, 0, 0);
                    display: block;
                    visibility: visible;
                }
            }
        }
    }

    &--no-mobile {
        @media (max-width: 768.98px) {
            margin-inline: 0;

            .carousel-control-prev,
            .carousel-control-next {
                display: none;
            }

            .carousel-inner {
                flex-wrap: wrap;
            }

            .carousel-item {
                display: flex !important;
                position: relative !important;
                top: 0 !important;
                right: 0 !important;
                z-index: 1 !important;
                float: none !important;
                padding: 10px 0 !important;
                margin: 0 !important;

                &--hide-all-visible {
                    display: none !important;
                }
            }
        }
    }
}
